import apiWhatsAppList from '@/views/pages/komship/customer/Broadcast/service/api.whatsAppList.service'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    whatsAppList: [],
  },
  mutations: {
    UPDATE_WHATSAPP_LIST(state, value) {
      state.whatsAppList = value
    },
  },
  actions: {
    async getWhatsAppList({ commit }) {
      try {
        const { data } = await apiWhatsAppList()
        const listKomchat = data.data.filter(item => item.type === 'komchat')
        commit('UPDATE_WHATSAPP_LIST', listKomchat)
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failed',
            text: error.response.data.error,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
